<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">申遗承诺履行情况</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
                @submit.native.prevent
            >
                <el-form-item
                    label="承诺事项来源"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.CNLY"
                        :disabled="true"
                        placeholder=""
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="承诺事项内容" :style="{ width: '80%' }">
                    <el-input
                        v-model="formdata.dataDic.CNSXNR_ZW"
                        :disabled="true"
                        placeholder="请填写承诺事项内容"
                        type="textarea"
                        :autosize="{ minRows: 3 }"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="年份" :style="{ width: '80%' }">
                    <el-date-picker
                        v-model="formdata.dataDic.NF"
                        type="year"
                        value-format="yyyy"
                        :disabled="!isEdit"
                        placeholder="请选择年份"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    label="目前进展"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-select
                        v-model="formdata.dataDic.MQJZ"
                        :disabled="!isEdit"
                        placeholder="请选择目前进展"
                    >
                        <el-option
                            v-for="{ CODE, LABEL } in enumObj.MQJZ"
                            :key="CODE"
                            :label="LABEL"
                            :value="CODE"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="对完成情况的必要说明"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.DWCQKDBYSM"
                        :disabled="!isEdit"
                        placeholder="请填写对完成情况的必要说明"
                        type="textarea"
                        :autosize="{ minRows: 3 }"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="相关文档" :style="{ width: '80%' }">
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="formdata.metaJson"
                        basicType="document"
                    ></basic-upload>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../0_com_js/info_mixin.js";
import { mapActions } from "vuex";
export default {
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                dataDic: {
                    glycbtid: this.heritageId,
                    CNLY: null,
                    CNSXNR_ZW: null,
                    ID: "",
                    CNSXID: null,
                    NF: null,
                    MQJZ: "",
                    DWCQKDBYSM: "",
                    JCDXID: null,
                },
                fileids: "",
                itemCode: "101",
                metaJson: [],
            },
            rules: {
                MQJZ: [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    mounted() {
        this.getCnsxDetail();
    },
    props: {},
    methods: {
        ...mapActions(["getGatherData"]),
        // 获取枚举数据
        async getCnsxDetail() {
            this.formdata.dataDic.CNSXID = this.$route.query.CNSXID;
            let params = {
                heritageId: this.heritageId,
                itemid: 33,
                id: this.formdata.dataDic.CNSXID,
            };
            let result = await this.getGatherData(params);
            if (result.issuccess) {
                if (result.resultvalue.savejson.length) {
                    this.formdata.dataDic.CNLY =
                        result.resultvalue.savejson[0].cnly;
                    this.formdata.dataDic.CNSXNR_ZW =
                        result.resultvalue.savejson[0].cnsxnr_zw;
                } else {
                    this.formdata.dataDic.CNLY = this.$route.query.CNLY;
                    this.formdata.dataDic.CNSXNR_ZW = this.$route.query.CNSXNR_ZW;
                }
            }
        },
    },
};
</script>

<style lang="scss">
.basic_data_edit {
    #basic-upload {
        padding: 0;
    }
}
</style>
